import React, { useEffect, useState } from "react";
import { getAllDocs } from "../Firebase.utils/Firebase.utils";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

function PropertiesForSaleCardsContainer({
  activeButton,
  searchTerm,
  properties,
}) {
  // const [properties, setProperties] = React.useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [newFilteredList, setNewFilteredList] = useState([]);
  // const [isLoading, setIsLoading] = React.useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const typeParam = searchParams.get("propertyType");
  const locationParam = searchParams.get("location");
  const searchParam = searchParams.get("search");

  //filter properties only those that are status of 30%

  const filteredLocation = properties.filter((property) => {
    if (locationParam === "all") {
      return property;
    }

    if (locationParam !== "all") {
      return property.address?.toLowerCase().includes(locationParam);
    }
  });

  const filteredType = filteredLocation.filter((property) => {
    if (typeParam === "all") {
      return property;
    }

    if (typeParam !== "all") {
      return property.category === typeParam;
    }
  });

  const filteredSearch = filteredType.filter((property) => {
    if (searchParam === "") {
      return property;
    }

    if (searchParam !== "") {
      return (
        property.property_name?.toLowerCase().includes(searchParam) ||
        property.address?.toLowerCase().includes(searchParam) ||
        property.listing_description?.toLowerCase().includes(searchParam) ||
        property.website_title?.toLowerCase().includes(searchParam)
      );
    }
  });

  const filteredPropertiesSearch = filteredSearch.filter((property) => {
    if (searchTerm === "") {
      return property;
    } else {
      return (
        property.property_name?.toLowerCase().includes(searchTerm) ||
        property.address?.toLowerCase().includes(searchTerm) ||
        property.listing_description?.toLowerCase().includes(searchTerm) ||
        property.website_title?.toLowerCase().includes(searchTerm)
      );
    }
  });

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/contactUs");
  };

  return (
    <div>
      {filteredPropertiesSearch.length !== 0 &&
        activeButton === "For Lease" && (
          <div className="mt-8 font-bold text-leechiuBlue">
            <Link to={`/offices`}>
              For Office Spaces for Lease{" "}
              <span className="underline">Click Here</span>
            </Link>
          </div>
        )}
      <div className="flex items-start justify-center gap-4 flex-wrap max-w-[1800px] mx-auto">
        {filteredPropertiesSearch.length === 0 && (
          <div className="p-4">
            <div className="text-center text-2xl font-bold text-leechiuBlue">
              No results found
            </div>
            <div className="mt-8">
              <Link
                to={`/offices`}
                className="text-center  text-leechiuBlue font-bold text-3xl "
              >
                For Office Spaces for Lease{" "}
                <span className="underline">Click Here</span>
              </Link>
            </div>
            <div className="mt-4">
              <button
                className="bg-leechiuOrange text-white px-4 py-2 rounded-lg"
                onClick={handleClick}
              >
                Contact Us for more listings
              </button>
            </div>
          </div>
        )}

        {filteredPropertiesSearch.map((property) => (
          <div key={property.id} className="mt-8">
            <Card
              title={property.property_name}
              photo={property.primaryPhotoUrl}
              price={property.total_price}
              description={property.listing_description}
              address={property.address}
              website_title={property.website_title}
              status={property.pipeline_status}
              id={property.id}
              websitePrice={property.websitePrice}
              listing_type={property.listing_type}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const Card = ({
  title,
  photo,
  price,
  description,
  address,
  website_title,
  status,
  id,
  websitePrice,
  listing_type,
}) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <motion.div
      className="rounded-xl min-h-[400px] w-96 overflow-hidden flex flex-col items-center justify-start
    shadow-xl bg-white  border-gray-200 border-[1px] relative hover:cursor-pointer 
    "
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
      onClick={() => setHovered(!hovered)}
    >
      <div className="absolute top-0 left-0 bg-leechiuOrange text-white px-2 py-1 font-serif">
        {listing_type}
      </div>
      {status === "100%" && (
        <div className="absolute top-10 bg-black bg-opacity-50  text-white rounded-lg px-4 py-2 font-serif">
          SOLD
        </div>
      )}
      {hovered && (
        <motion.div
          className="absolute inset-0 bg-neutral-950 bg-opacity-50 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <motion.div
            className="bg-white rounded-xl px-4 py-2 hover:bg-leechiuOrange hover:text-white"
            initial={{ y: 20 }}
            animate={{ y: 0 }}
          >
            <Link
              to={`/for-sale/details?name=${website_title || title}&id=${id}`}
            >
              Learn More
            </Link>
          </motion.div>
        </motion.div>
      )}
      <div className="overflow-hidden">
        <img
          src={
            photo ||
            "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721163707/jaaddyusz9znhnxeo2ee.jpg"
          }
          alt="title"
          className="h-60 w-96 object-cover"
        />
      </div>
      <div className="px-2 py-2 w-full">
        <div className="font-sans font-bold uppercase text-leechiuBlue text-left">
          {website_title || title}
          <div className="h-[2px] bg-opacity-55 mb-2 w-full bg-leechiuOrange"></div>
        </div>
        <div className="font-bold uppercase text-leechiuOrange text-xs text-left">
          Asking Price:{" "}
          {websitePrice
            ? websitePrice
            : `Php ${parseFloat(price).toLocaleString("en")}`}
          {/* {websitePrice || `Php {${parseFloat(price).toLocaleString("en")}}`} */}
        </div>
        <div className="text-leechiuBlue text-xs text-left mt-2">
          <strong>Address</strong>: {address}
        </div>

        <div className="text-xs text-left text-leechiuBlue">
          <strong>Description</strong>: {description}
        </div>
        {/* <div className="uppercase font-bold text-leechiuOrange text-xs text-left">Learn More</div> */}
      </div>
    </motion.div>
  );
};

export default PropertiesForSaleCardsContainer;
