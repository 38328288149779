import React from "react";

function InputBox({ value, name, onChange }) {
  return (
    <div className="flex flex-col items-start justify-center">
      <label className="text-sm font-semibold text-gray-800">Search</label>
      <input
        className="text-sm p-2 w-96 border-[1px] border-blue-900 rounded-lg text-gray-800"
        type="search"
        placeholder="Im looking for..."
        onChange={onChange}
        name={name}
        value={value}
      />
    </div>
  );
}

export default InputBox;
