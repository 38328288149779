import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAllDocs } from "../Firebase.utils/Firebase.utils";
import InsightsCard from "./InsightsCard";

function InsightsPage() {
  const [showModal, setShowModal] = React.useState(false);
  const [insights, setInsights] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState({});
  const [activeInsight, setActiveInsight] = React.useState(null);

  useEffect(() => {
    const fetchInsights = async () => {
      let data = await getAllDocs("insights");
      //arrange descending by date
      data.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });

      //remove unpublished insights
      data = data.filter((insight) => insight.published === true);

      setInsights(data);
      setIsLoading(false);
    };
    fetchInsights();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const fetchReq = async () => {
      const res = await fetch(
        "https://leechiu-app-email.vercel.app/api/insight",
        {
          method: "POST",
          body: JSON.stringify({
            email: formData?.email,
            firstName: formData?.clientName,
            link: activeInsight?.pdf,
            requestedFile: activeInsight?.title,
          }),
        }
      );
    };
    fetchReq();

    navigate("/thankyou");
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="bg-leechiuBlue text-white">
      {showModal && (
        <ModalForm
          setShowModal={setShowModal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setActiveInsight={setActiveInsight}
        />
      )}
      <div
        className="h-[500px] flex items-center"
        style={{
          backgroundImage: `url("https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720861950/leechiuweb3/Home/aerial-view-of-makati-2023-11-27-05-24-21-utc_udba4l.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue h-full w-full flex items-center bg-opacity-60">
          <div className="font-serif text-5xl text-white font-semibold text-left ml-10 sm:ml-20">
            <div>Property</div>
            <div>Market Insights</div>
          </div>
        </div>
      </div>
      <div className="p-20 text-left text-2xl">
        Make informed real estate decisions in the Philippines. Our
        comprehensive research and analysis provide the clarity you need.
      </div>

      <div className="flex items-center justify-between bg-white px-4 py-2 text-neutral-900 max-w-[1440px] m-auto">
        <div>
          <button
            className="bg-leechiuBlue text-white px-4 py-2 "
            style={{
              borderRadius: "10px 0 0 10px",
            }}
          >
            View All
          </button>
          <button
            className="px-4 py-2 border border-leechiuBlue"
            style={{
              borderRadius: "0 10px 10px 0",
            }}
          >
            Recently
          </button>
        </div>
        <div className="flex items-center gap-4">
          <div className="">
            <input
              className="border-2 border-leechiuBlue rounded-lg px-2 py-1 w-36"
              type="text"
              placeholder="Search"
            />
          </div>
          <button>Filter</button>
        </div>
      </div>

      <div className="bg-leechiuBlue my-8 space-y-4 w-full flex flex-col items-center justify-center">
        {insights.map((insight) => (
          <InsightsCard
            key={insight.id}
            insight={insight}
            setShowModal={setShowModal}
            activeInsight={activeInsight}
            setActiveInsight={setActiveInsight}
          />
        ))}
      </div>
    </div>
  );
}

const InputBox = ({ label, type, placeholder, onChange, ...otherprops }) => {
  return (
    <div className="flex flex-col mb-2">
      <label className="text-xs text-left text-white" htmlFor={label}>
        {label}
      </label>
      <input
        className="border border-leechiuOrange rounded-md p-2 text-neutral-900"
        type={type}
        id={label}
        placeholder={placeholder}
        onChange={onChange}
        {...otherprops}
      />
    </div>
  );
};

const ModalForm = ({
  setShowModal,
  handleChange,
  handleSubmit,
  setActiveInsight,
}) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
      <div className=" max-w-[500px] max-h-[500px] flex flex-col items-center justify-center bg-leechiuBlue px-8 py-8">
        <div className="text-sm w-full h-full">
          <div className="mt-8 mb-8 text-sm font-semibold">
            <div className="text-leechiuOrange uppercase text-lg mb-2">
              Market Insights Report Download{" "}
            </div>
            Please fill out the form below to download the report
          </div>
          <form className="flex flex-col w-full" onSubmit={handleSubmit}>
            <InputBox
              label="Name"
              type="text"
              placeholder="Enter your name"
              name="clientName"
              required="true"
              onChange={handleChange}
            />
            <InputBox
              label="Email"
              type="email"
              placeholder="Enter your email"
              name="email"
              required="true"
              onChange={handleChange}
            />
            <InputBox
              label="Message"
              type="text"
              placeholder="Enter your message"
              name="message"
              required="true"
              onChange={handleChange}
            />
            <div className="flex items-center justify-center gap-4">
              <button
                className="hover:bg-leechiuOrange px-4 py-2"
                onClick={() => {
                  setShowModal(false);
                  setActiveInsight(null);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="hover:bg-leechiuOrange px-4 py-2"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InsightsPage;
