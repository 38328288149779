import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function PseAccreditation() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Announcement | Leechiu Property Consultants</title>
        <meta
          name="description"
          content={
            "Leechiu Property Consultants is Now Accredited by PSE for Property Valuation"
          }
        />
      </Helmet>
      <div className=" flex items-center justify-center flex-wrap">
        <div
          style={{
            backgroundImage: `url(https://res.cloudinary.com/dtnbpkyvd/image/upload/v1727925169/leechiuweb3/officePhoto.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className="lg:w-1/2 w-full object-cover lg:h-[500px] h-[300px]"
        ></div>
        <div className="w-full lg:w-1/2 text-left p-4">
          <div className="font-serif font-bold text-4xl text-leechiuBlue ">
            {
              "Leechiu Property Consultants, Inc. is Now Accredited by PSE for Property Valuation"
            }
          </div>
          <div className="text-sm text-leechiuOrange mt-8">
            By: Editorial Team
          </div>
          <div className="mt-4  text-leechiuBlue text-xl">October 1, 2024</div>
        </div>
      </div>

      <div className="border-b border-gray-100 mt-8"></div>
      <div className="p-4 text-left max-w-[1080px] mx-auto">
        <div className="mt-4 text-gray-600 space-y-4 [&>*]:text-justify">
          <div>
            <strong>Makati City, Philippines Oct 1, 2024 </strong>– Leechiu
            Property Consultants, Inc. (LPC), a leading property consultancy
            firm in the Philippines, today announced it has been accredited by
            the Philippine Stock Exchange (PSE) as a qualified property
            appraiser. This accreditation authorizes LPC to provide property
            valuations for listed companies and prospective applicants seeking
            to list on the PSE.
          </div>

          <div>
            With 9 years of experience in the Philippine real estate market, LPC
            has established itself as a trusted advisor to a diverse clientele.
            The firm's team of highly qualified and experienced appraisers
            possesses a deep understanding of valuation methodologies and
            techniques, ensuring accurate and reliable assessments across a wide
            range of property types. These include residential, commercial, and
            industrial properties, as well as specialized assets such as plant,
            building, and heavy machinery equipment.
          </div>

          <div>
            "This accreditation by the PSE is a significant milestone for LPC
            and reinforces our commitment to delivering the highest standards of
            professionalism and expertise in the field of property valuation,"
            said Roy Golez, Director of Research and Consultancy. "We are proud
            to be recognized by the PSE as a trusted partner in providing
            independent and objective valuations that meet the stringent
            requirements of the exchange."
          </div>

          <div>
            LPC's accreditation by the PSE underscores the firm's dedication to
            upholding industry best practices and maintaining the integrity of
            property valuations in the Philippine market.
          </div>

          <div>
            To know more about our valuation and property appraisal services,{" "}
            <Link className="underline" to="/contactUs">
              contact us.
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
