import React, { useEffect } from "react";
import useGetAllDocs from "../hooks/useGetAllDocs";
import { setDocData } from "../Firebase.utils/Firebase.utils";
import { setDoc } from "firebase/firestore";

export default function ListingCategorization() {
  const { data: properties, isLoading } = useGetAllDocs({
    collection: "listings",
  });
  const [searchTerm, setSearchTerm] = React.useState("");

  const [propertyData, setPropertyData] = React.useState(properties);
  const [toggled, setToggled] = React.useState(false);

  useEffect(() => {
    if (properties) {
      setPropertyData(properties);
    }

    if (toggled) {
      setPropertyData(
        properties.filter(
          (property) => !property.category || property.category === "all"
        )
      );
    }
  }, [properties, toggled]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const categories = [
    { value: "all", label: "All" },
    { value: "residential house and lot", label: "Residential House and Lot" },
    { value: "condominium", label: "Condominium" },
    { value: "industrial", label: "Industrial" },
    { value: "commercial", label: "Commercial" },
    { value: "raw land", label: "Raw Land" },
    { value: "hotels and resorts", label: "Hotels and Resorts" },
    { value: "office", label: "Office" },
  ];

  const listheaders = [
    {
      name: "Property Name",
      value: "property_name",
      inputType: "text",
    },
    {
      name: "listing_category",
      value: "category",
      inputType: "select",
    },
  ];

  const handleSelect = async (e, property) => {
    await setDocData("listings", property.id, {
      category: e.target.value,
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleMissingCategory = () => {
    setToggled(!toggled);
  };

  const filteredProperties = propertyData.filter(
    (property) =>
      property.property_name
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      property.zoning?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.listing_description
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  //sort using property name
  filteredProperties.sort((a, b) =>
    a.property_name > b.property_name
      ? 1
      : a.property_name < b.property_name
      ? -1
      : 0
  );

  return (
    <div>
      <button
        className="border border-gray-300 rounded-md p-2"
        onClick={toggleMissingCategory}
      >
        toggle missing category
      </button>
      <div>Categorize Listings</div>
      <div>
        <input
          type="search"
          className="border border-gray-300 rounded-md p-2"
          onChange={handleSearch}
        />
      </div>
      <div className="text-xs">
        {filteredProperties.map((property, index) => (
          <div key={index}>
            <div className="flex items-center gap-4 p-2 text-gray-600 border-b border-gray-300 text-left">
              <div className="min-w-72">{property.property_name}</div>
              <a
                href={`https://raven.leechiu.app/listing/${property.id}?link=Photos`}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
              <div className="min-w-36">{property.zoning}</div>
              <div className="">
                {property.inputType === "text" ? (
                  <input
                    type="text"
                    name={property.value}
                    value={property[property.value]}
                  />
                ) : (
                  <select
                    className="border border-gray-300 rounded-md"
                    name={property.value}
                    onChange={(e) => handleSelect(e, property)}
                    value={property.category || "all"}
                  >
                    {categories.map((category, index) => (
                      <option key={index} value={category.value}>
                        {category.label}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
