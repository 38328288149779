import React from "react";
import { Link } from "react-router-dom";

function Partners() {
  const partners = [
    {
      name: "uli",
      link: "https://uli.org/",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677426/leechiu/partners%20and%20affiliates/ulilogo_iudrgq.png",
    },
    {
      name: "ibpap",
      link: "https://www.ibpap.org/",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677424/leechiu/partners%20and%20affiliates/IBPAP_logo_o2d6mu.png",
    },
    {
      name: "eccp",
      link: "https://www.eccp.com/",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677424/leechiu/partners%20and%20affiliates/ECCP-Logo-Full-Colour_q7j0d2.png",
    },
    // {
    //   name: "ccap",
    //   link: "https://ccap.ph/",
    //   logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677425/leechiu/partners%20and%20affiliates/partner-4_vxpvki.png",
    // },
    {
      name: "nordcham",
      link: "https://nordcham.com.ph/",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677425/leechiu/partners%20and%20affiliates/Nordcham_Logo_qxycge.png",
    },

    {
      name: "amcham",
      link: "https://amchamphilippines.com/",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677424/leechiu/partners%20and%20affiliates/Amcham_xjs7mt.png",
    },

    {
      name: "himap",
      link: "https://himap.ph/",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677423/leechiu/partners%20and%20affiliates/HIMAP-logo_jymzvw.png",
    },
    // {
    //   name: "map",
    //   link: "https://map.org.ph/",
    //   logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677423/leechiu/partners%20and%20affiliates/MAP-OGImage-1200x630-1_lytfu0.png",
    // },
    // {
    //   name: "psia",
    //   link: "https://www.psia.org.ph",
    //   logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677423/leechiu/partners%20and%20affiliates/11371_PSIA-Logo_wrrj6j.png",
    // },
    {
      name: "bccp",
      link: "https://britcham.org.ph/",
      logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677423/leechiu/partners%20and%20affiliates/3a8898d8-022a-4517-b53a-2d1c710251f4_xorzgu.png",
    },
    // {
    //   name: "wallace",
    //   link: "https://www.facebook.com/wbfph/",
    //   logo: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720677427/leechiu/partners%20and%20affiliates/Wallace_Business_Forum_WBF_LOGO_FINAL_ziy0ii.png",
    // },
  ];

  return (
    <div>
      <div>
        <div className="flex flex-wrap justify-center max-w-5xl m-auto gap-4">
          {partners.map((partner) => (
            <div className="m-4" key={partner.name}>
              <Link to={partner.link} target="_blank">
                <img src={partner.logo} alt={partner.name} className="h-12 " />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Partners;
