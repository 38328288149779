import React, { useEffect } from "react";
import { getAllDocsSnap } from "../Firebase.utils/Firebase.utils";

export default function useGetAllDocs({ collection }) {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await getAllDocsSnap(collection, (data) => {
        setData(data);
        setIsLoading(false);
      });
    };
    fetchData();
  }, []);
  return { data, isLoading };
}
