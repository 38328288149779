import React from "react";

function InsightsCard({ insight, setShowModal, setActiveInsight }) {
  const handleShowModal = () => {
    setShowModal(true);
    setActiveInsight(insight);
  };

  return (
    <div className="flex items-center justify-center flex-wrap">
      <div className="w-[350px] ">
        <img src={insight.image} alt={insight.title} />
      </div>
      <div className="max-w-[550px] text-left p-2">
        <h1 className="text-gray-600 bg-gray-200 font-bold px-2 py-1 text-center">
          {insight.title}
        </h1>
        <p className="text-white mt-4">{insight.description}</p>
        <div className="mt-4">
          <button
            className="bg-leechiuOrange text-white px-4 py-2"
            onClick={handleShowModal}
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
}

export default InsightsCard;
