import React from "react";
import SearchIcon from "@mui/icons-material/Search";

function SearchButton({ onClick }) {
  return (
    <div>
      <button
        onClick={onClick}
        className="bg-orange-600 text-white px-4 py-2 rounded-xl w-fit text-sm flex flex-row items-center gap-2"
      >
        <SearchIcon />
        Search
      </button>
    </div>
  );
}

export default SearchButton;
