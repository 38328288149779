import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import LeadersCollage from "./LeadersCollage";
import BrokerImages from "./BrokerImages";

function AboutUsMainPage() {
  const bgImg =
    "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721034480/leechiuweb3/Research%20and%20Property%20Valuation/finance-manager-meeting-discussing-company-growth-2023-11-27-04-57-13-utc_1_ycheiu.jpg";

  const leaders = [
    {
      name: "David Leechiu",
      position: "CEO",
      img: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1730094978/leechiuweb3/aboutus/leaders/David_Leechiu_nua04f.jpg",
    },
    {
      name: "Henry Cabrera",
      position: "Executive Director - Investment Sales",
      img: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1730095802/leechiuweb3/aboutus/leaders/LPC_Corporate_Photos_B5_-_Henry_Cabrera-2_tngyok.png",
    },
    {
      name: "Phillip Anonuevo",
      position: "Executive Director - Commercial Leasing",
      img: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1730095854/leechiuweb3/aboutus/leaders/LPC_Corporate_Photos_B5_-_Phillip_Anonuevo-4_wtklrj.png",
    },

    {
      name: "Lylah Ledonio",
      position: "Executive Director - Commercial Leasing",
      img: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1730095716/leechiuweb3/aboutus/leaders/LEDONIO_Lylah_a0qdao.jpg",
    },
    {
      name: "Roy Amado Golez",
      position: "Director - Research and Valuation",
      img: "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1730095650/leechiuweb3/aboutus/leaders/GOLEZ_Roy_n1y60o.jpg",
    },
  ];

  return (
    <motion.div
      className="bg-leechiuBlue "
      initial={{ x: -100 }}
      animate={{ x: 0 }}
      //   transition={{ duration: 0.5, type: "spring", stiffness: 100 }}
      ease="easeInOut"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Leechiu Property Consultants </title>
        <meta
          name="description"
          content={
            "Leechiu Property Consultants (LPC) is a privately held professional real estate brokerage services company operating in the Philippines."
          }
        />
      </Helmet>
      <div
        className="h-[500px] w-full"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue h-full w-full bg-opacity-60 text-white flex items-center justify-center ">
          <div className="font-serif text-4xl font-semibold text-left w-full p-8 sm:p-20">
            About Us
          </div>
        </div>
      </div>
      <div className="h-12 w-full" />

      <div className="mx-auto max-w-[1440px]">
        <div className="flex items-center justify-center flex-wrap ">
          <div className=" bg-red-50 h-[500px] w-full sm:w-1/2 ">
            <img
              src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721027144/leechiuweb3/Investment%20Sales/cirnffkcolrbccxcccv7.jpg"
              className="w-full h-full object-cover"
              alt=""
            />
          </div>
          <div className="h-[500px] w-full sm:w-1/2 bg-white p-8 flex items-center justify-start  flex-col ">
            <div className="flex items-center justify-center lg:gap-16 gap-4 text-leechiuBlue font-bold">
              <a href="/aboutUs/#company">Our Company</a>
              <a href="/aboutUs/#executive-leadership">Executive Leadership</a>
              <a href="/aboutUs/#brokers">Our Brokers </a>
              <a href="/aboutUs/#careers">Careers</a>
            </div>
            <div
              className="flex items-center justify-start mt-8 h-12 gap-2 w-full"
              id="company"
            >
              <div className="w-2 h-full bg-leechiuOrange"></div>
              <div className="font-serif font-bold text-3xl text-leechiuBlue text-left ">
                Our Company
              </div>
            </div>
            <div className="text-justify mt-4 text-sm sm:text-lg ">
              {` Leechiu Property Consultants, Inc. (LPC) is a privately held
                  professional real estate brokerage company founded by David Leechiu, the country’s top real estate adviser. LPC’s mission is to deliver profitable real estate solutions to its clients and partners. Since our establishment in 2016, we have leased more than one (1) million square meters of office space and               have sold thirty-two (32) billion pesos worth of real estate.`}
            </div>
            <div
              className="bg-leechiuOrange px-4 py-2 rounded-lg text-white text-lg self-start mt-8
            "
            >
              <Link to="/">Learn More</Link>
            </div>
          </div>
        </div>
        <div className="bg-white p-8"></div>
        <div
          className="flex items-center justify-center flex-wrap "
          id="executive-leadership"
        >
          <div className="h-[500px] w-full sm:w-1/2 bg-white p-8 flex items-center justify-start  flex-col">
            <div className="flex items-center justify-start mt-8 h-12 gap-2 w-full">
              <div className="w-2 h-full bg-leechiuOrange"></div>
              <div className="font-serif font-bold text-3xl text-leechiuBlue text-left ">
                Executive Leadership
              </div>
            </div>
            <div className="text-justify mt-4 text-sm sm:text-lg ">
              {
                "Meet the experienced team behind Leechiu Property Consultants. Learn more about our executive leadership, their vision, and their commitment to delivering exceptional results."
              }
            </div>
            <div
              className="bg-leechiuOrange px-4 py-2 rounded-lg text-white text-lg self-start mt-8
            "
            >
              <Link to="/">Learn More</Link>
            </div>
          </div>
          <div className=" bg-red-50  lg:h-[500px] w-full sm:w-1/2 ">
            <div>
              <LeadersCollage leaders={leaders} />
            </div>
          </div>
        </div>

        <div className="bg-white p-8"></div>
        <div
          className="flex items-center justify-center flex-wrap "
          id="brokers"
        >
          <div className=" bg-red-50 lg:h-[500px] w-full sm:w-1/2 flex items-center justify-center">
            <BrokerImages />
          </div>
          <div className="h-[500px] w-full sm:w-1/2 bg-white p-8 flex items-center justify-start  flex-col">
            <div className="flex items-center justify-start mt-8 h-12 gap-2 w-full">
              <div className="w-2 h-full bg-leechiuOrange"></div>
              <div className="font-serif font-bold text-3xl text-leechiuBlue text-left ">
                Our Brokers
              </div>
            </div>
            <div className="text-justify mt-4 text-sm sm:text-lg ">
              {
                "The team behind LPC leverages on their combined experience to negotiate even the most complex property transactions for both local proponents and the world’s biggest conglomerates."
              }
            </div>
            <div
              className="bg-leechiuOrange px-4 py-2 rounded-lg text-white text-lg self-start mt-8
            "
            >
              <Link to="/">Learn More</Link>
            </div>
          </div>
        </div>
        <div className="bg-white p-8"></div>
        <div
          className="flex items-center justify-center flex-wrap "
          id="careers"
        >
          <div className="h-[500px] w-full sm:w-1/2 bg-white p-8 flex items-center justify-start  flex-col">
            <div className="flex items-center justify-start mt-8 h-12 gap-2 w-full">
              <div className="w-2 h-full bg-leechiuOrange"></div>
              <div className="font-serif font-bold text-3xl text-leechiuBlue text-left ">
                Careers
              </div>
            </div>
            <div className="text-justify mt-4 text-sm sm:text-lg ">
              {
                "Explore exciting career opportunities at Leechiu Property Consultants. Join our dynamic team of real estate professionals and help shape the future of property in the Philippines."
              }
            </div>
            <div
              className="bg-leechiuOrange px-4 py-2 rounded-lg text-white text-lg self-start mt-8
            "
            >
              <Link to="/">Learn More</Link>
            </div>
          </div>
          <div className=" bg-red-50 h-[500px] w-full sm:w-1/2 ">
            <img
              src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1713799705/leechiu/Tenant%20Representation/ub6jlouenjabh3mj5rqf.jpg"
              className="w-full h-full object-cover"
              alt=""
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default AboutUsMainPage;
