import React, { useEffect } from "react";
import PropertiesForSaleCardsContainer from "./PropertiesForSaleCardsContainer";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import PropertySearchBar from "./PropertySearchBar";
import useGetAllDocs from "../hooks/useGetAllDocs";

function PropertiesForSale() {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeButton, setActiveButton] = React.useState("For Sale");
  const [activeListings, setActiveListings] = React.useState([]);

  const typeParam = searchParams.get("propertyType");
  const locationParam = searchParams.get("location");
  const searchParam = searchParams.get("search");
  const listingTypeParam = searchParams.get("listingType");

  const { data: properties = [], isLoading } = useGetAllDocs({
    collection: "listings",
  });

  useEffect(() => {
    //set active listings to pipeline_status of 30%
    if (properties) {
      setActiveListings(
        properties.filter((property) => property.pipeline_status === "30%")
      );
    }
  }, [properties]);

  const filteredProperties = activeListings.filter(
    (property) => property.listing_type === activeButton
  );

  if (isLoading) {
    return (
      <div>
        <div>Loading...</div>
      </div>
    );
  }

  const bgImage =
    "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721353594/for%20sale/aerial-view-of-makati-2023-11-27-05-24-21-utc_1_fxuqrd.jpg";

  const activeStyle = "w-fit px-4 py-2 bg-leechiuBlue text-white";
  const inactiveStyle = "w-fit px-4 py-2 bg-white text-black";

  const handleForSale = () => {
    setActiveButton("For Sale");
  };

  const handleForLease = () => {
    setActiveButton("For Lease");
  };
  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Properties For Sale | Leechiu Property Consultants</title>
        <meta
          name="description"
          content={
            "Looking to buy property in the Philippines? Browse Leechiu's diverse portfolio of properties for sale, including residential, commercial, and industrial listings nationwide."
          }
        />
      </Helmet>
      <div
        className="w-full h-[700px] lg:h-[400px]"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="h-full w-full bg-leechiuBlue bg-opacity-60 p-4">
          <div className="h-full w-full  flex flex-col items-center justify-center gap-2 max-w-[1440px] mx-auto">
            <div className="lg:pl-12  sm:pl-32 font-serif text-4xl text-white w-full text-left font-semibold">
              Properties for Sale and Lease
            </div>
            <div className="lg:pl-12 sm:pl-32 text-white text-left text-lg font-semibold">
              Find properties for sale across all major property types,
              including office, industrial, retail, and data centers. Let us
              guide you to your next investment or leasing opportunity.
            </div>
            {/* property search bar */}
            <div className="mt-8 text-white">
              <PropertySearchBar />
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between max-w-6xl m-auto mt-8 flex-wrap max-sm:ml-4 gap-4">
        <div className="flex items-center justify-center">
          <button
            className={
              activeButton === "For Sale" ? activeStyle : inactiveStyle
            }
            style={{
              borderRadius: "10px 0 0 10px",
              border: "1px solid #000",
            }}
            onClick={handleForSale}
          >
            For Sale
          </button>
          <button
            className={
              activeButton === "For Lease" ? activeStyle : inactiveStyle
            }
            style={{
              borderRadius: "0 10px 10px 0",
              border: "1px solid #000",
            }}
            onClick={handleForLease}
          >
            For Lease
          </button>
        </div>
        <div className="flex items-center justify-center gap-4 ">
          <input
            type="text"
            placeholder="Search for properties"
            className="min-w-60 px-4 py-2"
            style={{
              borderRadius: "10px",
              border: "1px solid #000",
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="px-4 py-2 w-fit "
            style={{
              borderRadius: "10px",
              border: "1px solid #000",
            }}
          >
            Filters
          </button>
        </div>
      </div>
      <div>
        <PropertiesForSaleCardsContainer
          activeButton={activeButton}
          searchTerm={searchTerm}
          properties={filteredProperties}
        />
      </div>
    </div>
  );
}

export default PropertiesForSale;
