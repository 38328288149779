import React from "react";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

function PropertyCard({
  image,
  type,
  name,
  location,
  description1,
  description2,
  id,
}) {
  return (
    <div
      className="min-h-[420px] w-[300px] bg-white overflow-hidden shrink-0 relative"
      style={{
        borderRadius: "10px",
      }}
    >
      <div
        className="h-[200px] bg-cover bg-center bg-no-repeat cursor-pointer hover:scale-110 transition-all duration-500"
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div className="p-4 text-left space-y-2">
        <div className="text-white text-sm absolute bg-leechiuOrange top-0 left-0 px-2 py-1">
          {type}
        </div>
        <div className="text-leechiuBlue text-lg  font-bold font-serif">
          {name}
        </div>
        <div className="text-xs font-bold text-leechiuBlue mt-4">
          {location}
        </div>
        <div className="text-neutral-800">
          <div className="text-xs">{description1}</div>
          <div className="text-xs">{description2}</div>
        </div>
        <Link className="text-leechiuBlue font-semibold text-xs mt-8 flex items-center gap-2 cursor-pointer">
          <Link to={`/offices/${id}?name=${name}`}>Learn More</Link>
          <div>
            <FiArrowRight className="text-xl" />
          </div>
        </Link>
      </div>
    </div>
  );
}

export default PropertyCard;
