import React from "react";
import ClientsLandlordRep from "./ClientsLandlordRep";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClientTestimonials from "./ClientTestimonials";
import { useEffect } from "react";
import { getDocDataSnap } from "../Firebase.utils/Firebase.utils";

function Valuation() {
  const [pageData, setPageData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchPageData = async () => {
      const pageDataRef = await getDocDataSnap(
        "website",
        "valuationAppraisal",
        (data) => {
          setPageData(data);
          setIsLoading(false);
        }
      );
    };
    fetchPageData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

 

  const backImg =
    "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721204400/leechiuweb3/PROJECT%20EVAL/new-strategies-2023-11-27-05-36-27-utc_1_ebx2sn.jpg";

  return (
    <div className="bg-leechiuBlue">
      <div
        className="h-[500px] w-full"
        style={{
          backgroundImage: `url(${backImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue h-full w-full bg-opacity-60 text-white flex items-center justify-center">
          <div className="font-serif text-4xl font-semibold">
            Valuation and Appraisal
          </div>
        </div>
      </div>
      <div className="h-20 w-full" />
      <FirstDescription pageData={pageData} />
      <Accreditation pageData={pageData} />
      <div className="h-12 w-full" />
      <DescriptionBand pageData={pageData} />
      <SolutionsCard pageData={pageData} />
      <ClientTestimonials pageData={pageData} />
      <ClientsLandlordRep pageData={pageData} />
    </div>
  );
}

const FirstDescription = ({ pageData }) => {
  return (
    <div className=" flex items-center justify-center flex-wrap">
      <div className="w-[600px] bg-white text-left px-16 py-8">
        <div className="font-semibold">
          <div className="font-serif text-4xl text-leechiuBlue">
            {pageData.tagline}
          </div>
        </div>
        <div className="text-leechiuBlue mt-8">{pageData.pageDescription}</div>
      </div>
      <div className="max-w-[800px]">
        <img
          src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721205129/leechiuweb3/PROJECT%20EVAL/shot-of-a-group-of-businesspeople-listening-to-a-p-2023-11-27-05-15-43-utc_1_pxq6nb.jpg"
          alt=""
          style={{
            maximumWidth: "800px",
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
};

export const Accreditation = () => {
  const accreditedAgencies = [
    {
      name: "PhilGEPS",
      logo: "https://notices.philgeps.gov.ph/assets/images/iLogo2%20v1.png",
      link: "https://www.philgeps.gov.ph/",
    },
    {
      name: "Bangko Sentral ng Pilipinas",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Bangko_Sentral_ng_Pilipinas_2020_logo.png/800px-Bangko_Sentral_ng_Pilipinas_2020_logo.png",
      link: "https://www.bsp.gov.ph/SitePages/Default.aspx",
    },
    {
      name: "Securities and Exchange Commission",
      logo: "https://www.sec.gov.ph/wp-content/uploads/2019/12/LogoNew-300x185.png",
      link: "https://www.sec.gov.ph/#gsc.tab=0",
    },
    {
      name: "Philippine Stock Exchange",
      logo: "https://cdn-asset.aseanexchanges.org/wp-content/uploads/2023/06/PSE-Logo.png",
      link: "https://www.pse.com.ph/",
    },
  ];

  return (
    <div className="text-leechiuBlue mt-12 bg-white p-8">
      <div className="font-serif text-2xl font-semibold text-center ">
        Our <span className="text-leechiuOrange">Accreditations</span>
      </div>
      <div className="text-black max-w-[1080px] mx-auto mt-4">
        {
          "Accredited by the PhilGEPS, Bangko Sentral ng Pilipinas, Securities and Exchange Commission, and Philippine Stock Exchange, we provide our clients with confidence and assurance in every transaction. This ensures financial compliance and professionalism within the Philippine property market."
        }
      </div>
      <div className="flex items-end justify-center gap-8 mt-8 flex-wrap">
        {accreditedAgencies.map((agency) => (
          <div className="flex items-center justify-center flex-col">
            <Link
              to={agency.link}
              target="_blank"
              className="flex flex-col items-center justify-center"
            >
              <div className="w-36 flex items-center justify-center flex-wrap">
                <img src={agency.logo} alt={agency.name} className="h-12" />
              </div>
              {/* <div className="text-xs">{agency.name}</div> */}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

const DescriptionBand = ({ pageData }) => {
  return (
    <>
      <div className=" xl:h-[500px] h-full w-full flex items-center justify-center xl:relative">
        <div className="hidden xl:block xl:h-[400px] w-full bg-white bg-opacity-60"></div>
        <div className=" xl:absolute top-0 left-0 w-full h-full flex items-center justify-center flex-wrap">
          <img
            src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721222128/leechiuweb3/PROJECT%20EVAL/people-meeting-brainstorming-blueprint-design-conc-2023-11-27-05-26-14-utc_1_u8foxw.jpg"
            alt=""
            className="h-[500px] w-[500px] object-cover"
          />
          <div className="h-full bg-leechiuBlue text-white flex items-center justify-center ">
            <div className="max-w-[600px] font-normal text-lg p-8 text-left">
              {pageData.photoCardDescription}

              <div className="border-2 mt-8 w-36 text-xs px-4 py-2 text-center">
                <Link to="/contactUs">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SolutionsCard = ({ pageData }) => {
  return (
    <div>
      <div className="text-white mt-8">
        <div className="font-serif text-4xl font-semibold">
          {"Our "}
          <span className="text-leechiuOrange">Solutions</span>
        </div>
        <div className="max-w-6xl m-auto mt-4">
          {pageData.solutionsDescription}
        </div>
      </div>
      <div className="flex items-center gap-4 p-8 flex-wrap justify-center">
        {pageData.cards.map((card, index) => {
          return (
            <div
              className="h-32
          max-w-[500px] bg-white  rounded-xl text-sm font-sans text-start flex items-center justify-center p-4 gap-1"
              key={index}
            >
              <CheckCircleOutlineIcon />
              <div className="text-leechiuBlue">
                <div className="font-semibold text-leechiuBlue text-xs sm:text-sm">
                  {card.title} : {""}
                  <span className="text-xs sm:text-sm font-normal">
                    {card.description}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Valuation;
