import React from "react";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

function NewsCard({ article }) {
  const {
    coverPhoto: image,
    title,
    articleDate,
    description,
    id: articleId,
    link,
  } = article;

  const replaceSpace = (str) => {
    return str.replace(/\s/g, "-");
  };

  return (
    <div
      className="h-[400px] w-[300px] bg-white overflow-hidden shrink-0"
      style={{
        borderRadius: "10px",
      }}
    >
      <div
        className="h-[200px] bg-cover bg-center bg-no-repeat cursor-pointer hover:scale-110 transition-all duration-500"
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div className="p-4 text-left">
        <div className="text-leechiuBlue text-sm  font-bold font-serif">
          {title}
        </div>
        <div className="text-sm text-leechiuBlue mt-4">{articleDate}</div>
        <div className="text-neutral-800">
          <div className="text-xs">{description}</div>
        </div>
        <Link
          to={`/news/${articleId}?title=${replaceSpace(title)}`}
          className="text-leechiuBlue font-semibold text-xs mt-8 flex items-center gap-2 cursor-pointer"
        >
          <div>Read More</div>
          <div>
            <FiArrowRight className="text-xl" />
          </div>
        </Link>
      </div>
    </div>
  );
}

export default NewsCard;
