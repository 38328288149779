import logo from "./logo.svg";
import "./App.css";
import Home from "./LandingPage/Home";
import { Routes, Route } from "react-router-dom";
import UnderConstruction from "./LandingPage/UnderConstruction";
import Navbar from "./LandingPage/Navbar";
import Footer from "./LandingPage/Footer";
import TenantRep from "./LandingPage/TenantRep";
import LandlordRep from "./LandingPage/LandlordRep";
import InvestmentSales from "./LandingPage/InvestmentSales";
import Research from "./LandingPage/Research";
import Valuation from "./LandingPage/Valuation";
import Residential from "./LandingPage/Residential";
import Hotels from "./LandingPage/Hotels";
import InsightsPage from "./Insights/InsightsPage";
import ContactUs from "./LandingPage/ContactUs";
import AboutUsMainPage from "./LandingPage/AboutUsMainPage";
import PropertiesForSale from "./LandingPage/PropertiesForSale";

import ThankYou from "./LandingPage/ThankYou";
import BuildingDetails from "./LandingPage/BuildingDetails";
import { QueryClient, QueryClientProvider } from "react-query";
import PropertyDetails from "./PropertyFinder/PropertyDetails";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { useState } from "react";
import Offices from "./LandingPage/Offices";
import ListProperty from "./LandingPage/ListProperty";
import { OfficeBuildingDetails } from "./LandingPage/OfficeBuildingDetails";
import ListingCategorization from "./devtools/ListingCategorization";
import PseAccreditation from "./LandingPage/PseAccreditation";
import News from "./LandingPage/News";
import NewsDetails from "./LandingPage/NewsDetails";
import Blogs from "./LandingPage/Blogs";
import BlogDetails from "./LandingPage/BlogDetails";

const queryClient = new QueryClient();

function App() {
  const { scrollY } = useScroll();
  const [hidden, setHidden] = useState(false);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 150) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <motion.div
          className="sticky top-0 z-10"
          variants={{
            visible: { y: 0 },
            hidden: { y: -100 },
          }}
          // animate={hidden ? "hidden" : "visible"}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <Navbar />
        </motion.div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutUs" element={<AboutUsMainPage />} />
          <Route path="/tenant-representation" element={<TenantRep />} />
          <Route path="/landlord-representation" element={<LandlordRep />} />
          <Route path="/investment-sales" element={<InvestmentSales />} />
          <Route path="/research-consultancy" element={<Research />} />
          <Route path="/valuation-property-appraisal" element={<Valuation />} />
          <Route path="/residential-sales-leasing" element={<Residential />} />
          <Route path="/hotels-tourism-leisure" element={<Hotels />} />
          <Route path="/insights" element={<InsightsPage />} />
          <Route path="/market-insights" element={<InsightsPage />} />
          <Route path="/news" element={<News />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<BlogDetails />} />
          <Route path="/news/:id" element={<NewsDetails />} />

          <Route
            path="/news/lpc-is-now-pse-accredited"
            element={<PseAccreditation />}
          />

          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/schedule" element={<ContactUs />} />
          <Route path="/list-properties" element={<ListProperty />} />
          <Route path="/for-sale" element={<PropertiesForSale />} />
          <Route path="/property-search" element={<PropertiesForSale />} />
          <Route path="/propertyFinder" element={<PropertiesForSale />} />
          <Route path="/thankyou" element={<ThankYou />} />

          <Route
            path="/devtools/listings"
            element={<ListingCategorization />}
          />
          <Route path="/offices" element={<Offices />} />
          <Route path="/offices/:id" element={<OfficeBuildingDetails />} />
          <Route path="/buildings/:id" element={<BuildingDetails />} />
          <Route path="/for-sale/details/" element={<PropertyDetails />} />
          <Route path="*" element={<UnderConstruction />} />
        </Routes>

        <Footer />
      </div>
    </QueryClientProvider>
  );
}

export default App;
