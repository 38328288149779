import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function ContactUs() {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;
    setFormData({
      ...formData,
      [input.name]: input.type === "checkbox" ? input.checked : input.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fetchReq = async () => {
      const res = await fetch("https://leechiu-app-email.vercel.app/api/send", {
        method: "POST",
        body: JSON.stringify({
          email: formData?.email,
          firstName: formData?.clientName,
        }),
      });
    };

    const passInfo = async () => {
      const res = await fetch(
        "https://leechiu-app-email.vercel.app/api/inquiry",
        {
          method: "POST",
          body: JSON.stringify({
            formData,
          }),
        }
      );
    };
    fetchReq();
    passInfo();

    navigate("/thankyou");
  };

  const bgImage =
    "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1720966156/leechiuweb3/contactUs_wppwup.jpg";

  const services = [
    { name: "Tenant Representation", value: "tenantRep" },
    { name: "Landlord Representation", value: "landlordRep" },
    { name: "Investment Sales", value: "investmentSales" },
    { name: "Residential Sales", value: "residentialSales" },
    { name: "Residential Leasing", value: "residentialLeasing" },

    { name: "Research and Consultancy", value: "researchConsultancy" },
    {
      name: "Hospitality, Tourism and Leisure",
      value: "hospitalityTourismLeisure",
    },
    { name: "Valuation and Appraisal", value: "valuationAppraisal" },
  ];

  return (
    <div className="flex items-start justify-center bg-leechiuBlue flex-wrap">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact | Leechiu Property Consultants </title>
        <meta
          name="description"
          content={
            "Get in touch with Leechiu Property Consultants. Find our contact information, office locations, and a convenient form to inquire about our services."
          }
        />
      </Helmet>
      <div
        className="h-[300px] sm:h-[800px] flex items-center justify-center"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue bg-opacity-60 text-white flex-wrap max-w-[600px] flex items-start flex-col justify-end sm:justify-center h-full pl-12 sm:pl-20 pb-4">
          <div className="font-serif text-3xl font-semibold max-sm:bg-leechiuBlue">
            Get in <span className="text-leechiuOrange">Touch</span>
          </div>
          <div className="text-sm text-left max-sm:text-xs max-sm:pr-8">
            Let us know how we may assist by leaving your contact information
            and details about your request below. We look forward to speaking
            with you soon.
          </div>
        </div>
      </div>

      <div className="h-full  max-w-[800px] bg-leechiuBlue text-white px-8 py-4 text-left ">
        <div className="font-serif text-3xl mb-4">How can we assist you</div>
        <div className="text-leechiuOrange font-bold">Services</div>
        <form onSubmit={handleSubmit}>
          <div
            className="text-left min-h-[200px] grid grid-cols-2 gap-2 mt-4

            "
          >
            {services.map((service) => (
              <div
                key={service.value}
                className="text-xs sm:text-sm flex items-center justify-start"
              >
                <input
                  type="checkbox"
                  name={service.value}
                  onChange={handleChange}
                />
                <label className="ml-2" htmlFor={service.value}>
                  {service.name}
                </label>
              </div>
            ))}
          </div>
          <div>
            <div className="text-leechiuOrange text-xs font-bold">
              {" "}
              *Required Fields
            </div>
            <div className="mt-4">
              <ContactForm
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

const InputBox = ({ label, type, placeholder, onChange, ...otherprops }) => {
  return (
    <div className="flex flex-col mb-2">
      <label className="text-xs text-white" htmlFor={label}>
        {label}
      </label>
      <input
        className="border border-leechiuOrange rounded-md p-2 text-neutral-900"
        type={type}
        id={label}
        placeholder={placeholder}
        onChange={onChange}
        {...otherprops}
      />
    </div>
  );
};

const ContactForm = ({ handleChange }) => {
  return (
    <div className="w-full flex gap-2 flex-wrap flex-col ">
      <InputBox
        label="Name*"
        type="text"
        placeholder="Enter your name"
        name="clientName"
        onChange={handleChange}
        required="true"
      />
      <InputBox
        label="Email*"
        type="email"
        placeholder="Enter your email"
        name="email"
        onChange={handleChange}
        required="true"
      />
      <InputBox
        label="Phone"
        type="tel"
        placeholder="Enter your phone number"
        name="tel"
        onChange={handleChange}
      />
      <InputBox
        label="Company*"
        type="text"
        placeholder="Enter your company name"
        name="company"
        required="true"
        onChange={handleChange}
      />
      <InputBox
        label="Message*"
        type="text"
        placeholder="Enter your message"
        required="true"
        name="message"
        onChange={handleChange}
      />
      <button
        className="bg-leechiuOrange text-white rounded-md p-2 mt-2 hover:bg-opacity-80"
        type="submit"
      >
        Submit
      </button>
    </div>
  );
};

export default ContactUs;
