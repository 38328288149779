import React from "react";
import { motion } from "framer-motion";
import "./Navbar.css";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

function PropertiesMenu({ setOpenMenuItem }) {
  const handleClick = () => {
    setOpenMenuItem(null);
  };

  const ListItems = [
    {
      name: "For Sale / Lease",
      link: "/property-search?propertyType=all&location=all&search=&listingType=For%20Sale",
    },
    // {
    //   name: "For Lease",
    //   link: "/offices",
    // },
    {
      name: "List your properties with us",
      link: "/list-properties",
    },
  ];

  return (
    <motion.div
      className="absolute z-10 h-full w-full bg-gray-800 bg-opacity-40 p-0 m-0 left-0 "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={handleClick}
    >
      <motion.div
        className={`${
          window.close ? null : "hidden"
        } bg-[url('https://res.cloudinary.com/dtnbpkyvd/image/upload/v1718206867/leechiucomingsoon/o-buildings_57223990_gbwi5b.jpg')] 
    bg-cover bg-center bg-no-repeat absolute z-20 w-full
    `}
        initial={{ y: "-200%" }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div
          className=" relative bg-leechiuBlue bg-opacity-95 text-white  flex items-center justify-center gap-8 p-8
     
      "
        >
          <div className={`font-serif text-2xl font-bold`}>
            <div className="text-white">Properties</div>
          </div>
          <div className="flex items-start justify-center gap-8 text-sm text-white">
            <div className="flex flex-col gap-8 items-start">
              {ListItems.map((item, index) => {
                return (
                  <Link to={item.link} key={index}>
                    <motion.div
                      className="cursor-pointer px-4 py-2"
                      whileHover={{
                        backgroundColor: "#FFFFFF",
                        color: "#00000",
                      }}
                    >
                      {item.name}
                    </motion.div>
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="absolute top-5 right-5">
            <button onClick={handleClick}>
              <CloseIcon />
            </button>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default PropertiesMenu;
