import React from "react";

function SearchBox({ options, label, name, value, onChange }) {
  return (
    <div className="flex flex-col text-gray-800 poppins-regular items-start justify-center">
      <label className="text-sm font-semibold">{label}</label>
      <select
        className="text-sm poppins-regular text-black p-2  rounded-lg w-48 border-[1px] border-blue-900"
        name={name}
        onChange={onChange}
        value={value}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SearchBox;
