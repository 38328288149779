import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import SearchBox from "./SearchBox";
import InputBox from "./InputBox";
import SearchButton from "./SearchButton";
import HeroInsights from "./HeroInsights";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

function PropertySearchBar() {
  const [searchParams, setSearchParams] = useSearchParams();

  const typeParam = searchParams.get("propertyType");
  const locationParam = searchParams.get("location");
  const searchParam = searchParams.get("search");
  const listingTypeParam = searchParams.get("listingType");

  const [formData, setFormData] = React.useState({
    propertyType: typeParam || "all",
    location: locationParam || "all",
    search: searchParam || "",
    listingType: listingTypeParam || "For Sale",
  });
  const [isProcessing, setIsProcessing] = React.useState(false);
  const navigate = useNavigate();

  const propertyTypes = [
    { value: "all", label: "All" },
    { value: "residential house and lot", label: "Residential" },
    { value: "condominium", label: "Condominium" },
    { value: "industrial", label: "Industrial" },
    { value: "commercial", label: "Commercial" },
    { value: "raw land", label: "Raw Land" },
    { value: "hotels and resorts", label: "Hotels and Resorts" },
    { value: "office", label: "Office" },
  ];

  const locations = [
    { value: "all", label: "All" },
    { value: "alabang", label: "Alabang" },
    { value: "bgc", label: "BGC" },
    { value: "batangas", label: "Batangas" },
    { value: "bulacan", label: "Bulacan" },
    { value: "cavite", label: "Cavite" },
    { value: "caloocan", label: "Caloocan" },
    { value: "cebu", label: "Cebu" },
    { value: "davao", label: "Davao" },
    { value: "iloilo", label: "Iloilo" },
    { value: "las pinas", label: "Las Pinas" },
    { value: "laguna", label: "Laguna" },
    { value: "makati", label: "Makati" },
    { value: "mandaluyong", label: "Mandaluyong" },
    { value: "manila", label: "Manila" },
    { value: "muntinlupa", label: "Muntinlupa" },
    { value: "ortigas", label: "Ortigas" },
    { value: "paranaque", label: "Paranaque" },
    { value: "pasay", label: "Pasay" },
    { value: "pasig", label: "Pasig" },
    { value: "quezon city", label: "Quezon City" },
    { value: "taguig", label: "Taguig" },
    { value: "valenzuela", label: "Valenzuela" },
    { value: "zamboanga", label: "Zamboanga" },
    { value: "all", label: "Others - type in search" },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(
      `/property-search?propertyType=${formData.propertyType}&location=${formData.location}&search=${formData.search}&listingType=${formData.listingType}`
    );
  };

  return (
    <div
      className=" bg-white flex flex-col p-2 space-y-2
    lg:flex-row lg:p-0 
    "
    >
      <div className="bg-leechiuBlue p-4 lg:p-2 flex items-center justify-center">
        <SearchIcon />{" "}
        <span className="lg:hidden text-lg">Search for Properties</span>
      </div>

      <div className="space-y-2 flex flex-col lg:flex-row lg:items-end lg:space-x-2 lg:p-2 lg:pb-4">
        <SearchBox
          options={propertyTypes}
          label={"Property Type"}
          name={"propertyType"}
          onChange={handleChange}
          value={formData.propertyType}
        />
        <SearchBox
          options={locations}
          label="Locations"
          name={"location"}
          onChange={handleChange}
          value={formData.location}
        />
        <InputBox
          label={"Search"}
          name={"search"}
          onChange={handleChange}
          value={formData.search}
        />
        <SearchButton onClick={handleSubmit} />
      </div>
    </div>
  );
}

export default PropertySearchBar;
