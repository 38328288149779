import React from "react";
import { useParams } from "react-router-dom";
import { getDocData } from "../Firebase.utils/Firebase.utils";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

//icons
import { IoCloudDownloadOutline } from "react-icons/io5";
import { IoMdPrint } from "react-icons/io";
import { FaShareAlt } from "react-icons/fa";
import InputBox from "../components/InputBox";
import AgentCard from "./AgentCard";

export function OfficeBuildingDetails() {
  const [buildingDetails, setBuildingDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [formData, setFormData] = useState({});

  const navigate = useNavigate();

  const extractCoordinates = (address) => {
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const matches = address?.match(regex);
    if (matches) {
      const lat = parseFloat(matches[1]);
      const lng = parseFloat(matches[2]);
      setCoordinates({ lat, lng });
    }
  };

  const { id } = useParams();

  useEffect(() => {
    const fetchBuildingDetails = async () => {
      const building = await getDocData("masterlist", id);
      setBuildingDetails(building);
      setIsLoading(false);
      // setSearchParams({ name: building.buildingName });
    };
    fetchBuildingDetails();
  }, []);

  useEffect(() => {
    extractCoordinates(buildingDetails.googleMapsPin);
  }, [buildingDetails]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const tableFields = [
    {
      label: "Building Name",
      value: "buildingName",
      inputType: "text",
    },
    {
      label: "Address",
      value: "address",
      inputType: "text",
    },
    {
      label: "Business District",
      value: "businessDistrict",
      inputType: "text",
    },
    {
      label: "City",
      value: "city",
      inputType: "text",
    },

    {
      label: "Developer",
      value: "developer",
      inputType: "text",
    },
    {
      label: "Completion Date",
      value: "yearConstructed",
      inputType: "text",
    },
    {
      label: "Certifications",
      value: "certifications",
      inputType: "text",
    },
    {
      label: "Total Floors",
      value: "totalFloors",
      inputType: "number",
    },
    {
      label: "Density",
      value: "density",
      inputType: "text",
    },

    {
      label: "Floor Efficiency",
      value: "floorEfficiency",
      inputType: "text",
    },

    {
      label: "AC System",
      value: "acSystem",
      inputType: "text",
    },

    {
      label: "Building Grade",
      value: "buildingGrade",
      inputType: "text",
    },
    {
      label: "Total Gross Leasable Area",
      value: "grossLeasableArea",
      inputType: "text",
    },
    {
      label: "Gross Leasable Area per floor",
      value: "floorPlate",
      inputType: "text",
    },
    {
      label: "Net Usable Area per floor",
      value: "netUseableArea",
      inputType: "text",
    },
    {
      label: "Backup Power",
      value: "backupPower",
      inputType: "text",
    },
    {
      label: "Elevators",
      value: "elevators",
      inputType: "text",
    },
    {
      label: "Telco Providers",
      value: "telcoProviders",
      inputType: "text",
    },
    {
      label: "Ceiling Height",
      value: "ceilingHeight",
      inputType: "text",
    },
    {
      label: "Parking Allocation",
      value: "parkingAllocation",
      inputType: "text",
    },
    {
      label: "24/7 Operations",
      value: "twentyFourSevenOperations",
      inputType: "text",
      placeholder: "Yes/No",
    },

    // {
    //   label: "Retail Tenant in Office Floors",
    //   value: "retailTenantInOfficeFloors",
    //   inputType: "text",
    //   placeholder: "Yes/No",
    // },
    // {
    //   label: "BPO",
    //   value: "bpo",
    //   inputType: "text",
    //   placeholder: "Yes/No",
    // },
    // {
    //   label: "Online Gaming",
    //   value: "onlineGaming",
    //   inputType: "text",
    //   placeholder: "Yes/No",
    // },
  ];

  const apikey = "AIzaSyCWwR_pdGlJwHWDaSLNl0oP4QtTD_W1ws0";
  const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${apikey}&q=${coordinates.lat},${coordinates.lng}`;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fetchReq = async () => {
      const res = await fetch(
        "https://leechiu-app-email.vercel.app/api/office",
        {
          method: "POST",
          body: JSON.stringify({
            email: formData?.email,
            contactName: formData?.contactName,
            message: formData?.message,
            phone: formData?.phone,
            buildingName: buildingDetails.buildingName,
            id: buildingDetails.id,
          }),
        }
      );
    };

    fetchReq();
    navigate("/thankyou");
  };

  const handleDownload = async () => {};

  const handlePrint = async () => {
    window.print();
  };

  const handleShare = async () => {
    //copy url to clipboard
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    alert("Link copied to clipboard, share it with your friends");
  };

  return (
    <div className=" m-auto relative" id="pdf-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {buildingDetails.buildingName} | Office Space | Leechiu Property
          Consultants
        </title>
        <meta name="description" content={buildingDetails.description} />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property="og:type" content={"website"} />
        <meta property="og:title" content={buildingDetails.buildingName} />
        <meta property="og:description" content={buildingDetails.description} />
        {/* End Facebook tags */}
        {/* Twitter tags */}
        {/* <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} /> */}
      </Helmet>

      <div className="bg-leechiuBlue h-96 w-full absolute -z-10 "></div>

      {/* first layer */}
      <div className="flex items-start justify-center w-[95%] flex-wrap mx-auto ">
        <div className="w-full lg:w-[700px]  text-left">
          <div className="text-white font-serif text-4xl font-bold pt-8">
            {buildingDetails.buildingName}
          </div>
          <div className="text-white text-sm pb-8">
            {buildingDetails.address}, {buildingDetails.city}
          </div>
          <div
            className="text-left h-[700px] object-contain"
            style={{
              backgroundImage: `url(${buildingDetails.primaryPhotoUrl})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>

        <div className="w-full lg:w-[500px] bg-white h-full mt-8">
          <div className="flex items-center justify-end gap-8 w-full text-right p-4 text-xs text-leechiuOrange">
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={handleDownload}
            >
              <IoCloudDownloadOutline />
              <div>Download</div>
            </div>
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={handlePrint}
            >
              <IoMdPrint />
              <div>Print</div>
            </div>
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={handleShare}
            >
              <FaShareAlt />
              <div>Share</div>
            </div>
          </div>

          <div className="font-serif text-leechiuBlue font-bold text-left text-2xl p-2">
            Get in <span className="text-leechiuOrange">Touch</span>
          </div>

          {/* forms */}
          <div className="p-4 shadow-lg">
            <form className="h-[400px]" onSubmit={handleSubmit}>
              <InputBox
                name={"contactName"}
                label="Name"
                placeholder="Enter your name"
                onChange={handleChange}
                required
              />
              <InputBox
                name={"email"}
                label="Email Address"
                placeholder="Enter your email address"
                onChange={handleChange}
                required
                type={"email"}
              />

              <InputBox
                name={"phone"}
                label="Phone Number"
                placeholder="Enter your phone number"
                onChange={handleChange}
                required
              />
              <InputBox
                name={"message"}
                label="Message"
                placeholder="Enter your message"
                onChange={handleChange}
                required
              />

              <button
                className="bg-leechiuBlue text-white px-4 py-2 mt-4"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>

          <div className="min-h-[300px] bg-gray-100 shadow-md">
            <div className="text-leechiuBlue font-serif text-xl font-bold text-left p-2 pt-8 ">
              Talk to our <span className="text-leechiuOrange">Agents</span>
            </div>

            <div className="p-2 flex items-center justify-normal flex-wrap gap-8">
              {buildingDetails.teamMembers.map((member, index) => {
                return <AgentCard key={index} member={member} />;
              })}
            </div>
          </div>
        </div>
      </div>

      {/* //property details and overview section */}
      <div className="flex items-start justify-center w-[90%] flex-wrap mx-auto mt-8 max-w-[1440px]">
        <div className="w-full lg:w-1/2 text-left">
          <div className="text-leechiuBlue font-serif text-xl px-4 py-4 font-bold">
            Property <span className="text-leechiuOrange">Overview</span>
          </div>
          <div className="text-sm p-4 ">{buildingDetails.description}</div>
          <div
            className="h-72 w-full mt-4"
            style={{
              backgroundImage: `url(${buildingDetails.floorPlanUrl})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>

        <div className="w-full lg:w-1/2 text-left">
          <div className="text-leechiuBlue font-serif text-xl font-bold px-4 py-4">
            Technical <span className="text-leechiuOrange">Information</span>
          </div>
          <div className="border border-gray-600 mx-4"></div>
          <div className="px-8 pt-8">
            <div className="flex items-center justify-between text-xs font-semibold text-leechiuBlue py-2 border-b border-gray-100">
              <div className="min-w-36">Address</div>
              <div className="text-right">{buildingDetails.address}</div>
            </div>

            <div className="flex items-center justify-between text-xs font-semibold text-leechiuBlue py-2  border-b border-gray-100">
              <div className="min-w-36">Developer</div>
              <div className="text-right">{buildingDetails.developer}</div>
            </div>

            <div className="flex items-center justify-between text-xs font-semibold text-leechiuBlue py-2  border-b border-gray-100">
              <div className="min-w-36">Completion Date</div>
              <div className="text-right">
                {buildingDetails.yearConstructed}
              </div>
            </div>

            <div className="flex items-center justify-between text-xs font-semibold text-leechiuBlue py-2  border-b border-gray-100">
              <div className="min-w-36">Certifications</div>
              <div className="text-right">{buildingDetails.certifications}</div>
            </div>

            <div className="flex items-center justify-between text-xs font-semibold text-leechiuBlue py-2  border-b border-gray-100">
              <div className="min-w-36">Typical Floor Plan</div>
              <div className="text-right">{buildingDetails.floorPlate}</div>
            </div>

            <div className="flex items-center justify-between text-xs font-semibold text-leechiuBlue py-2  border-b border-gray-100">
              <div className="min-w-36">Telco Providers</div>
              <div className="text-right">{buildingDetails.telcoProviders}</div>
            </div>

            <div className="flex items-center justify-between text-xs font-semibold text-leechiuBlue py-2  border-b border-gray-100">
              <div className="min-w-36">24/7 Operations</div>
              <div className="text-right">
                {buildingDetails.twentyFourSevenOperations}
              </div>
            </div>

            <div className="mt-8">
              <a
                className="text-leechiuOrange font-bold underline"
                href={buildingDetails.googleMapsPin}
                target="_blank"
                rel="noreferrer"
              >
                Click here to google maps pin
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* third layer */}
      <div className="flex items-start justify-center w-[90%] flex-wrap mx-auto mt-8 max-w-[1440px]">
        <div className="font-serif font-bold text-xl text-leechiuBlue text-left p-4 w-full">
          Location <span className="text-leechiuOrange">Overview</span>
        </div>
        <div className="w-full p-4">
          <iframe
            className="w-full lg:h-[700px] h-[500px]"
            src={mapUrl}
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

const BuildingDetailsTable = ({ formData, tableFields }) => {
  return (
    <div className="flex items-center flex-wrap max-w-5xl text-sm">
      <table className="w-full bg-white border border-gray-300  text-neutral-800 mt-4">
        <tbody>
          {tableFields.map((field, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
              style={{
                textAlign: "left",
              }}
            >
              <td className="border border-gray-300 px-2 py-1">
                {field.label}
              </td>
              {field.value === "totalAvailableSpace" ? (
                <td className="border border-gray-300 px-2 py-1">
                  {parseFloat(formData[field.value]).toLocaleString()} sqms
                </td>
              ) : (
                <td className="border border-gray-300 px-2 py-1">
                  {formData[field.value]}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
