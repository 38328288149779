import React, { useEffect } from "react";
import { getAllDocs } from "../Firebase.utils/Firebase.utils";

function AgentCard({ member }) {
  const [users, setUsers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await getAllDocs("users");

      setUsers(users);
      setIsLoading(false);
    };
    fetchUsers();
  }, []);

  const userDetails = users.find((user) => user.email === member);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="text-left text-leechiuBlue text-sm">
      <div className="font-bold text-lg">{userDetails.displayName}</div>
      <div>{userDetails.contactNumber}</div>
      <a className="hover:underline" href={`mailto:${member}`}>
        {member}
      </a>
    </div>
  );
}

export default AgentCard;
