import React, { useEffect } from "react";
import { storage } from "../Firebase.utils/Firebase.utils";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { motion } from "framer-motion";

export default function BrokerImages() {
  const [images, setImages] = React.useState([]);
  const [displayedImages, setDisplayedImages] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [changingIndex, setChangingIndex] = React.useState(null); // Index of the image being changed

  useEffect(() => {
    const fetchImages = async () => {
      const storageRef = ref(storage, "brokers");
      const listRef = await listAll(storageRef);
      const urls = await Promise.all(
        listRef.items.map(async (item) => {
          const url = await getDownloadURL(item);
          return url;
        })
      );
      setImages(urls);
      setIsLoading(false);
    };
    fetchImages();
  }, []);

  // Function to select 6 unique random images
  const selectRandomImages = (excludeIndexes) => {
    const uniqueIndexes = new Set(); // Use a Set to ensure unique indexes

    while (uniqueIndexes.size < 6) {
      const randomIndex = Math.floor(Math.random() * images.length);
      if (!excludeIndexes.includes(randomIndex)) {
        uniqueIndexes.add(randomIndex);
      }
    }

    return Array.from(uniqueIndexes);
  };

  // Select random images every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      const excludeIndexes = displayedImages.map((_, index) => index); // Exclude current displayed images' indexes
      const randomIndexes = selectRandomImages(excludeIndexes);
      const randomImages = randomIndexes.map(index => images[index]);
      setChangingIndex(Math.floor(Math.random() * randomImages.length)); // Set index of the changing image
      setDisplayedImages(randomImages);
    }, 5000);

    // Initial selection of random images
    if (images.length > 6) {
      const initialIndexes = selectRandomImages([]);
      const initialImages = initialIndexes.map(index => images[index]);
      setDisplayedImages(initialImages);
      setChangingIndex(Math.floor(Math.random() * initialImages.length)); // Setting the initial changing index
    }

    return () => clearInterval(interval);
  }, [images]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <motion.div className="flex items-center justify-center">
      <div className="flex items-center flex-wrap justify-center ">
        {displayedImages.map((imageUrl, index) => {
          const isChangingImage = index === changingIndex;
          return (
            <motion.div
              key={index}
              initial={{ opacity: isChangingImage ? 1 : 1 }}
              animate={{ opacity: isChangingImage ? 0 : 1 }} // Fade out the changing image
              transition={{ duration: isChangingImage ? 1 : 0 }} // Apply transition only if changing
              className="h-[200px] w-[200px] cursor-pointer"
              onAnimationComplete={() => {
                if (isChangingImage && changingIndex !== null) {
                  // Filter out images currently being displayed
                  const remainingImages = images.filter(
                    (_, idx) => !displayedImages.includes(images[idx])
                  );
                  // Pick a new unique image
                  const newImage = remainingImages[Math.floor(Math.random() * remainingImages.length)];
                  const newImages = displayedImages.slice();
                  newImages[changingIndex] = newImage; // Replace the fading image
                  setDisplayedImages(newImages);
                }
              }}
            >
              <img
                src={imageUrl}
                className="h-full w-full object-cover object-top bg-no-repeat"
                alt={`Broker ${index + 1}`}
              />
            </motion.div>
          );
        })}
      </div>
    </motion.div>
  );
}
