import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { getDocData } from "../Firebase.utils/Firebase.utils";
import { useParams } from "react-router-dom";
import Tiptap from "../tiptap/Tiptap";

export default function NewsDetails() {
  const [article, setArticle] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchArticle = async () => {
      const data = await getDocData("news", id);
      setArticle(data);
      setIsLoading(false);
    };
    fetchArticle();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const convertDate = (date) => {
    const d = new Date(date);
    //remove day e.g. monday
    const options = { year: "numeric", month: "long", day: "numeric" };
    return d.toLocaleDateString("en-US", options);
  };

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{article.title}</title>
        <meta name="description" content={article.title} />
      </Helmet>
      <div className=" flex items-center justify-center flex-wrap max-w-[1440px] mx-auto">
        <div
          style={{
            backgroundImage: `url(${article.coverPhoto})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className="lg:w-1/2 w-full object-cover lg:h-[500px] h-[300px]"
        ></div>
        <div className="w-full lg:w-1/2 text-left p-4">
          <div className="font-serif font-bold text-4xl text-leechiuBlue ">
            {article.title}
          </div>
          <div className="text-sm text-leechiuOrange mt-8">
            By: {article.author}
          </div>
          <div className="mt-4  text-leechiuBlue text-xl">
            {convertDate(article.date)}
          </div>
        </div>
      </div>

      <div className="border-b border-gray-100 mt-8"></div>
      <div className="p-4  max-w-[1080px] mx-auto text-gray-600 font-sans text-justify">
        <Tiptap
          content={article.content}
          placeholder="edit here"
          editable={false}
        />
      </div>
    </div>
  );
}
