import React from "react";

function InputBox({
  label,
  name,
  type,
  placeholder,
  onChange,
  value,
  ...otherProps
}) {
  return (
    <div className="text-left">
      <label htmlFor="" className="text-gray-600 font-bold text-xs">
        {label}
      </label>
      <input
        name={name}
        className="border border-gray-300 rounded-lg p-2 w-full mt-1"
        type={type}
        placeholder={placeholder}
        {...otherProps}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

export default InputBox;
