import React from "react";

import Hero from "./Hero";
import ServicesSection from "./ServicesSection";
import FeaturedProperties from "./FeaturedProperties";
import OurInsights from "./OurInsights";
import AboutUs from "./AboutUs";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import AnnouncementPSE from "./AnnouncementPSE";
import { getDocDataSnap } from "../Firebase.utils/Firebase.utils";

function Home() {
  const [pageData, setPageData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchPageData = async () => {
      const pageDataRef = await getDocDataSnap(
        "website",
        "homepage",
        (data) => {
          setPageData(data);
          setIsLoading(false);
        }
      );
    };
    fetchPageData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Leechiu Property Consultants</title>
        <meta
          name="description"
          content={
            "Leechiu Property Consultants: Your trusted partner for expert property consultancy and real estate services in the Philippines. Find your ideal property with our experienced team."
          }
        />
      </Helmet>
      <Hero />
      <ServicesSection />
      <FeaturedProperties pageData={pageData} />
      <div className="h-20 w-full" />
      {/* <OurInsights /> */}
      <AnnouncementPSE />
      <AboutUs pageData={pageData} />
    </div>
  );
}

export default Home;
