import React, { useState, useEffect } from "react";
import HeroInsights from "./HeroInsights";
import PropertySearchBar from "./PropertySearchBar";
import { motion } from "framer-motion";

function HeroCarousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const carouselItems = [
    // {
    //   id: 1,
    //   Item: <HeroInsights />,
    // },
    {
      id: 2,
      Item: <PropertySearchBar />,
    },
  ];

  useEffect(() => {
    let interval = null;

    if (!isHovered) {
      interval = setInterval(() => {
        setActiveIndex((prevIndex) =>
          prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isHovered, carouselItems.length]);

  return (
    <div
      className="rounded-xl p-2 min-h-48 flex items-center justify-center cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-center justify-center gap-4 text-center">
        <motion.div
          key={activeIndex}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
        >
          {carouselItems[activeIndex].Item}
        </motion.div>
      </div>
    </div>
  );
}

export default HeroCarousel;
